import React from "react";
import profileImg from "../assets/profileImg.png";
import SkillsBanner from "./SkillsBanner";

const HomeBanner = () => {
  const homeBannerContent = {
    title: "Nadeem Khalawi",
    subtitle: "Solutions developer & Technology consultant",
    text: "",
  };

  const Bio = () => {
    return (
      <div className="bio">
        {/* <h1 className="bio-title">{homeBannerContent.title}</h1> */}
        <p className="bio-subtitle">{homeBannerContent.subtitle}</p>
        <p className="bio-text">{homeBannerContent.text}</p>
      </div>
    );
  };
  return (
    <div className="HomeBanner">
      <img className="profile-image" src={profileImg} alt="" />
      <Bio />
      <SkillsBanner />
    </div>
  );
};

export default HomeBanner;
