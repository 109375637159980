import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import "./App.css";
import AppsContainer from "./Apps/AppsContainer";
import Resume from "./Resume/Resume";
import Footer from "./Footer";
import LWMGallary from "./LearnWithMe/LWMGallary";

//* create app component
function App() {
  AOS.init({
    duration: 2000,
  });
  const hints = {
    underConstruction: {
      title: "Thank you for visiting!",
      text: "This demonstration website is under construction, come back soon! In the meanwhile you can find me on",
    },
    getImpressed: {
      title: "Pay attention to this, you will be impressed!",
      text: 'Hello! I would like to thank you for checking my work. I created this website with the intention of demonstrating some of what im capable of doing with my coding skills -one year after I wrote my first "Hello World!" ever.',
    },
    idea: {
      title: "Discouver me...",
      text: "I used various techniques and created sample features to showcase my skills. You will discover them (and more about me) as you navigate through the site",
    },
    getImpressedOld: {
      title: "Pay attention to this, you will be impressed!",
      text: "Today, you are visiting a demonstration to some of what I became capable of doing since I worte my first ever line of code a year ago! In this website; I intentionally used various techniques and created various features to showcase my skills. You will discover them (and more about me) as you navigate through the site",
    },
    dynamicSite: {
      title: "Custom dynamic styling",
      text: "I built this dynamic, modular and responsive website using ReactJS, and styled it with SASS/SCSS. I tried to keep the design minimal with small touches to create contrast between the colors. Sections and items (including the header, the navbar, the logo, cards gallaries, cards, articles, images and hints) are resusable components I wrote, and can be dynamicly added, removed or updated, by modifying the related variables and records in the database. And the view is fully reponsive to various portable screen sizes",
    },
    eyeFreindly: {
      title: "An eye-freindly design for long hours of browsing",
      text: "I achieved that by minimizing the presence of blue color in the design, utilizing text-shadow and items opacity, using warm colors, and tweaking brightness and contrast values -obviously, this is not a website you would spend a lot of time browsing, but regardless, its an eye freindly",
    },
    notAll: {
      title: "This is not all...",
      text: "To create a seamless user experience, I have developed a CMS to be able to update the content and modify the style. Built with ReactJS and integrated with backend cloud services from Google Firebase. ---work in progress",
    },
    adultLearning: {
      title: "I have plenty of skills for you...",
      text: 'Before I became a developer, I spent about 15 years doing management consulting, holding senior management roles in society organizations & education institutes, and on-side doing what I loved the most: designing, developing and delevering great adult learning experiences. This has enforced what I can do with technology - to have an idea, have a look at these learning modules wich I created recently, in a format of "learn along".',
    },
    viewOnManagement: {
      title: "Here is an article component...",
      text: "you can add as many titles, paragraphs, sub-paragraphs as you need. The article is an object, with an array of paragraphs, and array of sub-paragraphs, all are mapped dynamicly into the article view. You can also author as many articles as you need - thanks to React router you can add them dynamicly to a blog. But I'm not a blogger, so I'll leave it to a single article I wrote for you about my ideal work environment, my strengths, and how you can get the best of me.",
    },
    personalMessage: {
      title: "",
      text: "I am an aspiring developer and technology enthusiast, capable of building web apps & API-driven business applications with ReactJS, NodeJS, Microsoft Azure and Microsoft Power Platform. I bring with me over ten years of rich experience in management, adult education and learning engineering. Im open for new opportunities, and if you feel that I can be a good help for you or your team, get in touch with me, I would be very happy to have a little conversation -and a coffee if possible- with you.",
    },
  };

  return (
    <div className="App">
      <Header hints={hints} />
      {/* <div style={{ paddingTop: "5rem" }} id="apps" data-aos="fade-up">
        <AppsContainer />
      </div>
      <div id="learn-with-me" data-aos="fade-up">
        <LWMGallary />
      </div>
      <div id="resume" data-aos="fade-up">
        <Resume />
      </div> */}

      {/* <MainView hints={hints} />
      <HireMe cards={cards} /> */}
      <Footer />
    </div>
  );
}

export default App;
