import React from "react";

const NavBar = () => {
  const navBarContent = {
    blogTitle: "Nadeem Khalawi",
    navBtn: [
      { text: "Home", link: "#" },
      { text: "Apps", link: "#apps" },
      { text: "Resume", link: "#resume" },
    ],
  };
  return (
    <div className="NavBar">
      <h1 className="blogTitle">{navBarContent.blogTitle}</h1>
      {/* <div className="group">
        {navBarContent.navBtn.map((item, i) => {
          return (
            <div key={i}>
              <a className="element" href={item.link}>
                {item.text}
              </a>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default NavBar;
