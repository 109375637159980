import React from "react";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { FaGit } from "react-icons/fa";
import {
  SiJavascript,
  SiReact,
  SiPowerbi,
  SiMicrosoftazure,
  SiFirebase,
  SiGooglecloud,
  SiLinux,
  SiMicrosoftsharepoint,
  SiElectron,
  SiMicrosoftoffice,
  SiTypescript,
  SiHtml5,
  SiCss3,
  SiNodeDotJs,
  SiWindows,
} from "react-icons/si";

const SkillsBanner = () => {
  return (
    <div className="SkillsBanner">
      <div className="skills-group">
        <h1>Cloud</h1>
        <SiFirebase className="skill" />
        <SiGooglecloud className="skill" />
        <SiMicrosoftazure className="skill" />
      </div>
      <div className="skills-group">
        <h1>Frontend</h1>
        <SiHtml5 className="skill" />
        <SiCss3 className="skill" />
        <SiJavascript className="skill" />
        <SiTypescript className="skill" />
        <SiReact className="skill" />
        <SiElectron className="skill" />
      </div>
      <div className="skills-group">
        <h1>Backend</h1>
        <SiNodeDotJs className="skill" />
        <AiOutlineConsoleSql className="skill" />
      </div>
      <div className="skills-group">
        <h1>DevOps</h1>
        <SiLinux className="skill" />
        <SiWindows className="skill" />
        <FaGit className="skill" />
      </div>
      <div className="skills-group">
        <h1>BI & Smart Workplace</h1>
        <SiPowerbi className="skill" />
        <SiMicrosoftsharepoint className="skill" />
        <SiMicrosoftoffice className="skill" />
      </div>
    </div>
  );
};

export default SkillsBanner;
