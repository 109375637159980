import React from "react";
import Contacts from "./Contacts";

const Footer = () => {
  return (
    <div className="Footer">
      <Contacts />
      <p className="footer-text">© 2021 Nadeem Khalawi</p>
    </div>
  );
};

export default Footer;
