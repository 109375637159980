import React, { useState } from "react";
import MessagesList from "./MessagesList";

import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyCuep9zkwtYal_3_YjPV_JQQVnq9DZxpek",
  authDomain: "get-to-know-me-2021.firebaseapp.com",
  projectId: "get-to-know-me-2021",
  storageBucket: "get-to-know-me-2021.appspot.com",
  messagingSenderId: "24911163544",
  appId: "1:24911163544:web:723258f4b545e162a1a0ed",
  measurementId: "G-V99NGW5618",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const LeaveAMessage = () => {
  const messages = firebase.firestore().collection("/messages");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    isPrivate: false,
    dateTime: "",
  });

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    console.log("A message was submitted by: " + formData.name);
    console.log(formData);
    messages
      .add(formData)
      .then((docRef) => {
        console.log("a message is created with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
    setFormData({ name: "", email: "", message: "", isPrivate: false });
    // e.preventDefault();
  };

  return (
    <div className="LAM">
      <MessagesList messages={messages} />
      <div className="LeaveAMessage">
        <h1>Leave me a message!</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Your name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="email"
            placeholder="Your email: it will not be shown to the public"
            value={formData.email}
            onChange={handleChange}
          />
          <textarea
            type="text"
            name="message"
            placeholder="Leave your message, and thank you in advance for your advice"
            value={formData.message}
            onChange={handleChange}
          />
          <div className="keep-it-private">
            <label>
              Keep this message private
              <span style={{ padding: "0 2px" }}></span>
              <input
                type="checkbox"
                name="isPrivate"
                checked={formData.isPrivate}
                value={formData.isPrivate}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="form-controller">
            <button
              type="submit"
              onClick={() => {
                setFormData({
                  ...formData,
                  dateTime: new Date().toLocaleString(),
                });
              }}
            >
              submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeaveAMessage;
