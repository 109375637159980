import React from "react";
import { SiLinkedin, SiGithub, SiGmail } from "react-icons/si";

const Contacts = () => {
  return (
    <div className="Contacts">
      <a
        href="mailto:nadeem.khalawi@powercoders.org?subject=Contact from Get-To-Know-Me!"
        className="contact-icon"
      >
        <SiGmail />
      </a>
      <a
        href="https://www.linkedin.com/in/nadeemkhalawi/"
        target="_blank"
        rel="noreferrer"
        className="contact-icon"
      >
        <SiLinkedin />
      </a>
      <a
        href="https://github.com/Nadeempoco"
        target="_blank"
        rel="noreferrer"
        className="contact-icon"
      >
        <SiGithub />
      </a>
    </div>
  );
};

export default Contacts;
