import React from "react";

const Hint = (props) => {
  return (
    <div className={props.hintStyle}>
      {/* <BsInfo className="hint-icon" /> */}
      <div className="hint-box">
        {/* <FaRegLightbulb className="hint-icon" /> */}
        <h1 className="hint-title">{props.hint.title}</h1>
        <h1 className="hint-text">{props.hint.text}</h1>
      </div>

      {/* {props.hintText.map((item, i) => {
        return (
          <p key={i} className="hint-text">
            {item}
          </p>
        );
      })} */}
    </div>
  );
};

export default Hint;
