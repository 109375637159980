import React from "react";
import NavBar from "./NavBar";
import HomeBanner from "./HomeBanner";
import Hint from "./Hint";
import Contacts from "./Contacts";

const Header = (props) => {
  return (
    <div className="Header">
      <NavBar />
      <HomeBanner />
      <Hint hint={props.hints.personalMessage} hintStyle={"hint-center"} />
      {/* <Contacts /> */}
      {/* <Hint hint={props.hints.getImpressed} hintStyle={"hint-center"} />
      <Hint hint={props.hints.idea} hintStyle={"hint-right"} />
      <Hint hint={props.hints.dynamicSite} hintStyle={"hint-left"} />
      <Hint hint={props.hints.eyeFreindly} hintStyle={"hint-right"} />
      <Hint hint={props.hints.notAll} hintStyle={"hint-left"} /> */}
    </div>
  );
};

export default Header;
